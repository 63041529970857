var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1450px","persistent":"","scrollable":"","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.permissionCan('create'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$emit('new')}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("MENU.NEW"))+" "+_vm._s(_vm.$t("MENU.GALLERY"))+" ")]):_vm._e()]}}]),model:{value:(_vm.modalData.dialog),callback:function ($$v) {_vm.$set(_vm.modalData, "dialog", $$v)},expression:"modalData.dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle)+" ")]),_c('v-btn',{staticClass:"close-button",attrs:{"icon":""},on:{"click":_vm.handleCloseModalForm}},[_c('v-icon',[_vm._v(" mdi-close")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-text-field',{attrs:{"rules":_vm.nameRules,"label":_vm.$t('FORMS.name'),"id":_vm.dynamicID,"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.name']},on:{"keyup":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.name'] = ''}},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].name),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "name", $$v)},expression:"formModel.translations[selectedLocale.lang].name"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[(
                _vm.formModel.id && _vm.formModel.translations[_vm.selectedLocale.lang]
              )?_c('v-text-field',{attrs:{"label":_vm.$t('FORMS.slug'),"rules":_vm.nameRules,"id":_vm.dynamicID,"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.slug']},on:{"keyup":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.slug'] = ''}},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].slug),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "slug", $$v)},expression:"formModel.translations[selectedLocale.lang].slug"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.text"))+" "),(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('ckeditor',{attrs:{"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.text'],"config":_vm.editorConfig},on:{"input":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.text'] = ''}},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].text),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "text", $$v)},expression:"formModel.translations[selectedLocale.lang].text"}}):_vm._e(),(_vm.messages['translations.' + _vm.selectedLocale.lang + '.text'])?_c('small',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.messages["translations." + _vm.selectedLocale.lang + ".text"])+" ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('CustomFieldComponent',{attrs:{"customFieldName":"images","value":_vm.formModel.translations[_vm.selectedLocale.lang].images || '',"config":{
                type: 'media_selector',
                maxFiles: -1,
                inputFields: [
                  {
                    name: 'alt_text',
                    label: 'FORM_INPUT_NAMES.alt_text',
                    type: 'text',
                  },
                  {
                    name: 'title',
                    label: 'FORM_INPUT_NAMES.title',
                    type: 'text',
                  },
                  {
                    name: 'description',
                    label: 'FORM_INPUT_NAMES.description',
                    type: 'text',
                  },
                  {
                    name: 'url',
                    label: 'FORM_INPUT_NAMES.url',
                    type: 'text',
                  } ],
                selectButtonText: 'FORM_INPUT_NAMES.select_image',
                initialColumnClass:
                  'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
              }},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mb-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.handleCloseModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.cancel"))+" ")]),(
          (_vm.permissionCan('create') && !_vm.formModel.id) ||
          _vm.permissionCan('update')
        )?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.handleSaveModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e()],1)],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }